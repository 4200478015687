import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import Incident from "../incidents/incident"
import Loading from "../common/loading"
import Pagionation from "../common/pagination"

const IncidentList = ({incidents, loading}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const pageSize = 25;
  let incidentsList = [];

  const handlePageChange = (page) => {
    setCurrentPage(page);
  }

  useEffect(() => {
    setCurrentPage(1);
  }, [incidents])


  if(loading){
    incidentsList = (<Loading />)
  }else if(incidents && incidents.length > 0) {
    incidentsList = []
    for(let i = (currentPage - 1) * pageSize; i < currentPage * pageSize && i < incidents.length; i++) {
      let inc = incidents[i];
      let key = "incident-" + inc.ID;
      incidentsList.push(
        <Incident 
        id={inc.ID}
        key={key}
        type={inc.IncidentType}
        country={inc.Country}
        city={inc.City}
        region={inc.Region} 
        current={inc.Status === "current"}
        impact={inc.Impact} 
        start_date={inc.StartDate.substring(0,10)}
        reporting_date={inc.ReportingDate.substring(0, 10)}
        zipcodes={inc.Zipcodes} 
        description={inc.Description}
        sourceName={inc.Source}
        sourceUrl={inc.DataSourceUrl} 
        pinned={inc.Pinned == 1}
        delay={inc.ExpectedDelayText} />)
    }
  } else if(incidents != null && incidents.length == 0) {
    incidentsList = (<div className="no-incidents">No incidents found</div>)
  }
  return (
    <div className="events">
      <h2>History of events</h2>
      {incidentsList}
      <Pagionation itemsCount={incidents.length} pageSize={pageSize} currentPage={currentPage} onPageChange={handlePageChange} />
    </div>
  )

}

IncidentList.propTypes = {
  incidents: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
}
export default IncidentList