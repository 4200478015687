import React, {useState, useEffect} from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import useFilters from "../components/common/filters"
import "../components/map/filters.scss"
import "../components/alert-history/alert-history.scss"
import Filters from "../components/map/filters"
import Countries from "../components/map/countries"
import IncidentList from "../components/alert-history/incident-list"
import { getIncidents } from "../services/api"
import DateFilters from "../components/alert-history/date-filters"
import { CSVGenerator } from "../components/alert-history/incidentCsvGenerator"
import { getUser } from "../services/auth"


const AlertHistoryPage = () => {
  const [filters, countries, filterController, countryController] = useFilters();
	const [showSidebar, setShowSidebar] = useState(null)
  const [incidents, setIncidents] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(()=>{
    let filtersWithCountries = filters;
		filtersWithCountries.countries = countries;
    loadIncidents(filtersWithCountries)
  },[filters, countries])

  const loadIncidents = (_filters) => {
    setLoading(true)
    
    if (getUser().userType === 'NDD only'){
      _filters.source = ['PostNL ISA','PostNL Supply Chain'];
    }
    _filters.countries = countries;
    _filters.enabled = 0;

    getIncidents(_filters).then(incidents => {
			if(incidents != null) {
				setIncidents(incidents)
			} else {
        setIncidents([])
      }
      setLoading(false)
		})
  }

  const exportIncidents = () => {
    let csvGenerator = new CSVGenerator(incidents);
    csvGenerator.generate();
  }

  let contents = (<> </>);

  if(showSidebar == 'countries') {
		contents = (
			<Countries countries={countries}
			countryController={countryController}
			closeCallback={ (e) => { setShowSidebar(null) } } />
    )
	} else if (showSidebar == 'filters') {
		contents = (
			<Filters
			setFilterCallback={ filterController.mapFilterCallback}
			filters={filters}
			closeCallback={ (e) => { setShowSidebar(null) } }/>
		)
	}


  return (
    <Layout>
      <Seo title="PostNL Early Warning System" />
      <div className="container">
        <div className="alert-history-wrapper">
          <div className={"filter-menu" + (showSidebar ? " expand" : "")}>
            <div className="icon-wrapper">
              <i className={ (showSidebar == 'countries' ? 'active ' : '' ) + "icon icon-world"} onClick={(e) => {setShowSidebar('countries'); } }></i>
              <i className={ (showSidebar == 'filters' ? 'active ' : '' ) + "icon icon-filter"} onClick={(e) => {setShowSidebar('filters') }}></i>            
              <i className={ (showSidebar == 'refresh' ? 'active ' : '' ) +"icon icon-refresh"} onClick={(e) => {filterController.toggleFilter('reset')}}></i>
              <i className="icon icon-download" onClick={e => exportIncidents()}></i>            
            </div>
            <div className="content-wrapper">
              {contents}
            </div>
          </div>
          <div className="row">
            <div className="col-md-7 col-sm-12">
              <IncidentList incidents={incidents} loading={loading} />
            </div>
            <aside className="col-md-5 col-sm-12">
              <DateFilters filters={filters} loadIncidentsCallback={loadIncidents} />
            </aside>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default AlertHistoryPage
