import React from "react"
import PropTypes from "prop-types"
import "./pagination.scss"
import { StaticImage } from "gatsby-plugin-image"


const Pagionation = ({ itemsCount, pageSize, currentPage, onPageChange }) => {
  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = [];
  for (let i = 1; i <= pagesCount; i++) {
    pages.push(i)
  }

  const maxPagesToShow = 5;
  let startPage = Math.max(currentPage - Math.floor(maxPagesToShow / 2), 1);
  let endPage = Math.min(startPage + maxPagesToShow - 1, pagesCount);

  if (endPage - startPage < maxPagesToShow - 1) {
    startPage = Math.max(endPage - maxPagesToShow + 1, 1);
  }

  const renderPageNumbers = () => {
    const pageNumbers = [];
    if (startPage > 1) {
      pageNumbers.push(1);
      if (startPage > 2) {
        pageNumbers.push('...');
      }
    }
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }
    if (endPage < pagesCount) {
      if (endPage < pagesCount - 1) {
        pageNumbers.push('...');
      }
      pageNumbers.push(pagesCount);
    }
    return pageNumbers;
  };



  return (
    <nav aria-label="Page navigation">
      <ul className="pagination">
        {currentPage > 1 && (
          <li className="page-item previous-page" onClick={() => onPageChange(currentPage - 1)}>
            <a className="page-link">
              <StaticImage src="../../../static/images/icons/arrow-left.svg" placeholder="none" alt="previous incident page" />
            </a>
          </li>
        )}
        {renderPageNumbers().map(page => (
          <li
            key={page}
            className={page === currentPage ? "page-item active" : "page-item"}
            onClick={page === '...' ? () => {} : () => onPageChange(page)}
          >
            <a className="page-link">{page}</a>
          </li>
        ))}
        {currentPage < pagesCount && (
          <li className="page-item next-page" onClick={() => onPageChange(currentPage + 1)}>
            <a className="page-link">
              <StaticImage src="../../../static/images/icons/arrow-right.svg" placeholder="none" alt="next incident page" />
            </a>
          </li>
        )}
      </ul>
    </nav>
  )
}

Pagionation.propType = {
    itemsCount: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    currentPage: PropTypes.number.isRequired,
    onPageChange: PropTypes.func.isRequired,
}
export default Pagionation;