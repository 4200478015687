import React, {useState, useEffect} from "react"
import PropTypes from "prop-types"
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import "./date-filters.scss"

const DateFilters = ({filters, loadIncidentsCallback}) => {
  const [startDate, setStartDate] = useState(new Date((new Date()).setMonth((new Date()).getMonth() - 2)));
  const [endDate, setEndDate] = useState(new Date());
  

  useEffect(()=>{
    if (!startDate || !endDate){
      return
    }

    let _filters = filters;
    _filters.dateRange = {
      "StartDate": formatDate(startDate), 
      "EndDate": formatDate(endDate),
    }
    loadIncidentsCallback(_filters);
  },[startDate, endDate])
  

  const handleReportingDateChange = (e) => {
    let endDate = new Date()
    let startDate = new Date()
    switch(e.target.value){
      case "day":
        startDate.setDate(endDate.getDate() - 1);
        break;
      case "week":
        startDate.setDate(endDate.getDate() - 7);
        break;
      case "2 weeks":
        startDate.setDate(endDate.getDate() - 14);
        break;
      case "month":
        startDate.setMonth(endDate.getMonth() - 1);
        break;
      case "2 months":
        startDate.setMonth(endDate.getMonth() - 2);
        break;
      case "4 months":
        startDate.setMonth(endDate.getMonth() - 4);
        break;
      case "year":
        startDate.setFullYear(endDate.getFullYear() - 1);
        break;
      default:
        startDate.setDate(endDate.getMonth() - 2);
        break;
    }

    setStartDate(startDate)
    setEndDate(endDate)
  }

  const handleOrderBy = (e) => {
    let _filters = filters;
    _filters.orderBy = e.target.value;
    loadIncidentsCallback(_filters);
  }


  return (
    <div className="date-filters">
      <div className="date-picker">
        <label className="date-label">Start- and end date</label>
        <div className="selection-wrapper">
          <DatePicker
            className="start-date"
            selectsStart
            selected={startDate}
            onChange={date => setStartDate(date)}
            startDate={startDate}
            maxDate={endDate}
          />
          <DatePicker
            className="end-date"
            selectsEnd
            selected={endDate}
            onChange={date => setEndDate(date)}
            endDate={endDate}
            startDate={startDate}
            minDate={startDate}
            maxDate={new Date()}
          />
          <span className="calender-icon">
            <i className="icon icon-calendar"></i>
          </span>
        </div>
      </div>
      <div className="dropdown-wrapper">
        <div className="date-dropdown">
          <label className="date-label">Reporting date</label>
          <div className="selectdiv">
            <label>
              <select defaultValue="2 months" onChange={e => handleReportingDateChange(e)}>
                <option value="day">Last Day</option>
                <option value="week">Last Week</option>
                <option value="2 weeks">Last 2 Weeks</option>
                <option value="month">Last Month</option>
                <option value="2 months">Last 2 months</option>
                <option value="4 months">Last 4 months</option>
                <option value="year">Last year</option>
              </select>
            </label>
          </div>
        </div>
        <div className="date-dropdown">
          <label className="date-label">Order by</label>
          <div className="selectdiv">
            <label>
              <select defaultValue="pinned" onChange={e => handleOrderBy(e)}>
                <option value="pinned">Pinned</option>
                <option value="impact_desc">Impact (High first)</option>
                <option value="impact_asc">Impact (Low first)</option>
                <option value="age_desc">Age (Newest first)</option>
                <option value="age_asc">Age (Oldest first)</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    </div>
  )
}


DateFilters.propTypes = {
  filters: PropTypes.object.isRequired,
}
export default DateFilters

function formatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-indexed
  const day = String(date.getDate()).padStart(2, '0');
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const seconds = String(date.getSeconds()).padStart(2, '0');

  return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
}