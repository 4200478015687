export class CSVGenerator {
  constructor(incidents) {
    this.incidents = incidents;
    this.csvContent = "data:text/csv;charset=utf-8,";
    this.headers = [
      "ID", "IncidentType", "Country", "Region", "City", "Status", "Impact", 
      "StartDate", "ExpectedEndDate", "ReportingDate", "Zipcodes", "Description", "Source", 
      "DataSourceUrl", "Pinned", "ExpectedDelayText", "CreatedBy", "LatLng",
      "Status", "Size", "Enabled",
    ];
  }

  addRow(rowArray) {
    let row = rowArray.join(";");
    this.csvContent += row + "\r\n";
  }


  generate() {
    this.addRow(this.headers);
    for(let i = 0; i < this.incidents.length; i++) {
      let incident = this.incidents[i];
      let row = [];
      for(let j = 0; j < this.headers.length; j++) {
        let header = this.headers[j];
        let column = incident[header];
        if (!column) {
          column = "";
        }
        if (typeof column === 'string') {
          column = column.replace(/;/g, " ");
          column = column.replace(/\r?\n|\r/g, " ");
          if (header === "LatLng") {
            column = column.replace(" ", ",");
          }
        }
        row.push(column);
      }
      this.addRow(row); 
    }

    let encodedUri = encodeURI(this.csvContent);
    let link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "incidents_export.csv");
    document.body.appendChild(link);

    link.click();
  }

}

